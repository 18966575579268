import { useCallback } from 'react';
import { B2CTokens, RequestError } from '@mottmac-moata/identity-sdk';
import { hasAccessTokenExpired } from 'src/utils/tokenHelpers';
import { useAppSelector } from 'src/store/useAppSelector';
import { getB2cTokens, getPolicy } from 'src/store/auth/selectors';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { identityApiClient } from 'src/services/api/identityApiClient';
import { setTokens } from 'src/store/auth';
import AuthenticationError from 'src/store/errors/AuthenticationError';

const checkIsAuthenticated = (b2cTokens?: B2CTokens): b2cTokens is B2CTokens => {
  return !!b2cTokens?.access_token && !hasAccessTokenExpired(b2cTokens.access_token);
};

export interface UseAuthResult {
  isAuthenticated: boolean;
  refreshTokens: (signal?: AbortSignal) => Promise<B2CTokens>;
  getTokens: (signal?: AbortSignal) => Promise<B2CTokens>;
  getAccessToken: (signal?: AbortSignal) => Promise<string>;
}

const useAuth = (): UseAuthResult => {
  const dispatch = useAppDispatch();
  const policy = useAppSelector(getPolicy);
  const b2cTokens = useAppSelector(getB2cTokens);

  const isAuthenticated = checkIsAuthenticated(b2cTokens);

  const refreshTokens = useCallback(
    async (signal?: AbortSignal): Promise<B2CTokens> => {
      if (!b2cTokens?.refresh_token || !policy) {
        throw new AuthenticationError('You are not logged in');
      }

      try {
        const newTokens = await identityApiClient.OAuth2.refreshToken(
          { refresh_token: b2cTokens.refresh_token, policy },
          { signal }
        );

        dispatch(setTokens(newTokens));

        return newTokens;
      } catch (e) {
        if (e instanceof RequestError) {
          // a request error indicates that the request was handled by
          // the server but was invalid for some reason.
          // as the request is invalid, we can assume that a token
          // refresh is not possible

          // throwing an AuthenticationError will log the user out
          // in the requestErrorHandlingMiddleware
          throw new AuthenticationError('Your session has expired. Please log in again.');
        }
        throw e;
      }
    },
    [b2cTokens?.refresh_token, dispatch, policy]
  );

  const getTokens = useCallback(
    async (signal?: AbortSignal): Promise<B2CTokens> => {
      if (isAuthenticated) return b2cTokens;
      // the token may be refreshable when it's about to expire,
      // or it may be not refreshable when it's already expired.
      // so the request below may fail.
      return refreshTokens(signal);
    },
    [isAuthenticated, b2cTokens, refreshTokens]
  );

  const getAccessToken = useCallback(
    async (signal?: AbortSignal): Promise<string> => {
      return (await getTokens(signal)).access_token;
    },
    [getTokens]
  );

  return {
    isAuthenticated,
    refreshTokens,
    getTokens,
    getAccessToken,
  };
};

export default useAuth;

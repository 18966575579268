import { B2CTokens } from '@mottmac-moata/identity-sdk';
import { identityApiClient } from 'src/services/api/identityApiClient';

import StateNotReadyForActionError from 'src/store/errors/StateNotReadyForActionError';
import { AuthState } from '../types';

/**
 * this is the auth flow, when redirected back to Identity app from Azure B2C login after authenticated.
 */
const authoriseWithCode = async (
  code: string,
  authState: AuthState,
  signal: AbortSignal
): Promise<{
  b2cTokens: B2CTokens;
  policy: string;
}> => {
  const { policy } = authState;

  if (!policy) {
    throw new StateNotReadyForActionError('no stored authentication policy - we were not ready for this callback');
  }

  const b2cTokens = await identityApiClient.OAuth2.getToken({ code, policy }, { signal });

  return {
    b2cTokens,
    policy,
  };
};

export default authoriseWithCode;

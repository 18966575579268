import { Box, Typography } from '@mui/material';
import { UserProjectDto } from '@mottmac-moata/identity-sdk';
import useContentQuery, { ContentValue } from 'src/services/api/useContentQuery';

export interface ProjectSummaryType {
  story: ContentValue;
}

// eslint-disable-next-line @rushstack/no-new-null
const useProjectSummary = (userProjectDto?: UserProjectDto): JSX.Element | undefined | null => {
  const { projectSummaryUrl, projectSummary } = userProjectDto ?? {};
  const shouldRequireCmsProjectSummary = !!projectSummaryUrl;

  const { data: projectSummaryContent, isError: isProjectSummaryContentError } = useContentQuery<ProjectSummaryType>(
    projectSummaryUrl ?? '',
    {
      story: {
        path: 'sections[?(@.name=="Information")].fields[?(@[0].alias=="story")][0].value',
      },
    },
    {},
    { enabled: shouldRequireCmsProjectSummary }
  );

  // while userProjectDto is loading, return undefined
  if (userProjectDto === undefined) {
    return undefined;
  }

  const userProjectDtoProjectSummary =
    projectSummary && projectSummary !== '' ? <Typography>{projectSummary}</Typography> : null;

  // if we don't have a project summary url, just return the project summary from the project metadata
  if (!shouldRequireCmsProjectSummary) {
    return userProjectDtoProjectSummary;
  }

  // whilst CMS content is loading, return undefined
  if (projectSummaryContent === undefined && !isProjectSummaryContentError) {
    return undefined;
  }

  // on error, we just use the project summary from the project endpoint
  if (isProjectSummaryContentError) {
    return userProjectDtoProjectSummary;
  }

  // we definitely have the summary content
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return <Box>{projectSummaryContent!.story}</Box>;
};

export default useProjectSummary;

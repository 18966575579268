import { jwtDecode } from 'jwt-decode';

// https://dev.azure.com/mmdigitalventures/Moata/_wiki/wikis/Module%20-%20Identity/4475/Access-token-details
interface IdentityJwtPayload {
  /**
   * User Id
   */
  sub: string;
  iss: string;
  aud: string;
  iat: number;
  nbf: number;
  /**
   * Expiry time in seconds
   */
  exp: number;
  /**
   * The policy
   */
  tfp: string;
}

export interface IdentityAccessTokenPayload extends IdentityJwtPayload {
  /**
   * User email at the first array item
   */
  emails: string[];
  /**
   * Display name
   */
  name?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  given_name?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  family_name?: string;
}

export interface IdentityProjectScopedAccessTokenPayload extends IdentityAccessTokenPayload {
  accessScope: 'project';
  projectId: number;
  groupIds: string[];
  actions: string[];
}

export const decodeJwtToken = jwtDecode<IdentityJwtPayload>;

export const decodeAccessToken = jwtDecode<IdentityAccessTokenPayload>;

export const getJwtExpiresAt = (jwt: string): number => {
  const { exp } = decodeJwtToken(jwt);
  return exp * 1000;
};

export const getJwtExpDate = (jwt: string): Date => {
  const expMs = getJwtExpiresAt(jwt);
  return new Date(expMs);
};

export const getJwtPolicy = (jwt: string): string => {
  const policy = decodeJwtToken(jwt).tfp;
  if (!(typeof policy === 'string' && policy.length > 0)) {
    throw new Error('tfp claim in token must be a non-empty string');
  }
  return policy;
};

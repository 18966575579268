import { useGetCallbackRedirectAuthParams } from 'src/features/auth/hooks/useGetCallbackRedirectAuthParams';
import { CallbackRedirectAuthParam } from 'src/features/auth/constants';
import { buildProjectUrl } from './buildProjectUrl';

type GetProjectUrl = (params: {
  projectSource: string;
  projectId?: number;
  projectName?: string;
  productId?: number;
  resourceName?: string;
  isProjectScopedTokenEnabled?: boolean;
}) => Promise<string>;

interface UseGetProjectUrlReturn {
  getProjectUrl: GetProjectUrl;
}

export const useGetProjectUrl = (): UseGetProjectUrlReturn => {
  const { getCallbackRedirectAuthParams } = useGetCallbackRedirectAuthParams();

  const getProjectUrl: GetProjectUrl = async ({
    projectSource,
    projectId,
    projectName,
    productId,
    resourceName,
    isProjectScopedTokenEnabled,
  }) => {
    const authParams = await getCallbackRedirectAuthParams({
      isProjectScopedTokenEnabled,
      projectId,
    });

    return buildProjectUrl(projectSource, {
      token: `${authParams[CallbackRedirectAuthParam.AccessToken]}&${new URLSearchParams(authParams).toString()}`,
      projectId,
      projectName,
      productId,
      resourceName,
    });
  };

  return { getProjectUrl };
};

import { useMemo } from 'react';
import { UserProductWithAccessDto } from '@mottmac-moata/identity-sdk';
import { SerializedError } from '@reduxjs/toolkit';
import { UserAPI } from 'src/services/api/UserAPI';
import { useAppSelector } from 'src/store/useAppSelector';

export const useUserProducts = (): {
  allProducts?: UserProductWithAccessDto[];
  adminProducts?: UserProductWithAccessDto[];
  isLoading: boolean;
  error?: SerializedError;
} => {
  const { data: products, isLoading, error } = UserAPI.useGetProductsWithAccess();
  const user = useAppSelector((state) => state.auth.user);

  const adminProducts = useMemo(
    () => products?.filter((product) => product.isProductAdmin || user?.isSuperAdmin === 'True'),
    [user, products]
  );

  return {
    allProducts: products,
    adminProducts,
    isLoading,
    error: error ?? undefined,
  };
};

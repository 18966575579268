import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Button, Tabs, Tab, Stack } from '@mui/material';
import { FetchErrorResult } from 'src/ui/Result';
import Search from 'src/ui/Search';
import { UserAPI } from 'src/services/api/UserAPI';
import { useAppSelector } from 'src/store/useAppSelector';
import { useProductCreateModal } from '../ProductCreateModal';
import { useProjectEditModal } from '../ProjectEditModal';
import LazyRenderProjectsGrid from './LazyRenderProjectsGrid';

const ProjectListTabs = {
  My: 'my',
  All: 'all',
};

interface RecentProjectsListProps {
  onLoaded: () => void;
}

const RecentProjectsList: FC<RecentProjectsListProps> = ({ onLoaded }) => {
  const user = useAppSelector((state) => state.auth.user);

  const isSuperAdmin = user?.isSuperAdmin === 'True';

  const { openProjectEditModal } = useProjectEditModal();
  const { openProductCreateModal } = useProductCreateModal();

  const { data: productAccessData } = UserAPI.useGetProductsWithAccess();

  const canCreateProjects = useMemo(
    () => !!productAccessData?.some((data) => data.canCreateProjects),
    [productAccessData]
  );

  const [projectsFilter, setProjectsFilter] = useState('');

  const {
    data: allProjects,
    isLoading,
    isError,
  } = UserAPI.useFetchProjects({
    orderBy: 'recentprojects',
    displayAdminUsers: false,
    showMyAdministrationProjects: true,
  });

  useEffect(() => {
    if (allProjects !== undefined) {
      onLoaded();
    }
  }, [allProjects, onLoaded]);

  const [selectedProjectList, setSelectedProjectList] = useState(ProjectListTabs.My);

  const onProjectsTabSelect = (_: SyntheticEvent, newTab: string): void => setSelectedProjectList(newTab);

  const shouldShowAllProjectsTab = useMemo(
    () => isSuperAdmin || !!allProjects?.some((project) => project.isProductAdmin),
    [isSuperAdmin, allProjects]
  );

  if (isError) return <FetchErrorResult />;

  const myProjects = allProjects?.filter((project) => project.isMember);

  const filteredAllProjects = allProjects?.filter((project) =>
    project.name?.toLowerCase().includes(projectsFilter.toLocaleLowerCase())
  );

  const filteredMyProjects = myProjects?.filter((project) =>
    project.name?.toLowerCase().includes(projectsFilter.toLocaleLowerCase())
  );

  return (
    <>
      <Stack
        direction={{ sm: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
        gap={{ xs: 3, sm: 1 }}
        p={3}
        paddingBottom={0}
      >
        <Tabs value={selectedProjectList} onChange={onProjectsTabSelect}>
          <Tab
            label={`My Projects${filteredMyProjects ? ` (${filteredMyProjects.length})` : ''}`}
            value={ProjectListTabs.My}
          />
          {shouldShowAllProjectsTab && (
            <Tab
              label={`All Projects${filteredAllProjects ? ` (${filteredAllProjects.length})` : ''}`}
              value={ProjectListTabs.All}
            />
          )}
        </Tabs>
        <Stack ml={{ sm: 'auto' }} gap={2} direction={{ xs: 'column', sm: 'row' }}>
          <Search
            dataGtmEvent="data-gtm-project-search"
            label="Search Projects"
            name="searchProjects"
            filterValue={projectsFilter}
            disabled={!allProjects}
            onFilterValueChange={(newFilterValue) => setProjectsFilter(newFilterValue)}
          />

          {canCreateProjects && <Button onClick={() => openProjectEditModal()}>New Project</Button>}
          {isSuperAdmin && <Button onClick={() => openProductCreateModal()}>New Product</Button>}
        </Stack>
      </Stack>
      {selectedProjectList === ProjectListTabs.My && (
        <LazyRenderProjectsGrid
          projects={filteredMyProjects}
          isLoading={isLoading}
          isEmptyDataResult={myProjects?.length === 0}
          isNoSearchResult={!!myProjects?.length && !filteredMyProjects?.length}
          isSuperAdmin={isSuperAdmin}
          userId={user?.id}
        />
      )}
      {selectedProjectList === ProjectListTabs.All && (
        <LazyRenderProjectsGrid
          projects={filteredAllProjects}
          isLoading={isLoading}
          isEmptyDataResult={allProjects?.length === 0}
          isNoSearchResult={!!allProjects?.length && !filteredAllProjects?.length}
          isSuperAdmin={isSuperAdmin}
          userId={user?.id}
        />
      )}
    </>
  );
};

export default RecentProjectsList;
